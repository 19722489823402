@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
}
blockquote p {
  display: inline;
}

.faq-answer table {
  border-collapse: collapse;
}
.faq-answer td {
  border: 1px solid #999;
  padding: 0.75rem;
}
